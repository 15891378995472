<template>
  <v-container
    fluid
  >
    <v-card>
      <v-subheader>
        {{ $vuetify.lang.t('$vuetify.patient.informtion') }}
      </v-subheader>
      <v-card-text
        v-if="healthReport"
      >
        <v-row>
          <v-col
            cols="6"
          >
            <span
            >
              {{ $vuetify.lang.t('$vuetify.patient.name') }} :
            </span>

            <span
              class="ml-2 black--text"
            >
              {{ healthReport.patientName }}
            </span>

            <span
              class="ml-2 black--text"
            >
              {{ $vuetify.lang.t(`$vuetify.gender.${ healthReport.gender.toLowerCase() }`) }}
            </span>

            <span
              class="ml-2 black--text"
            >
              {{ healthReport.age }}
            </span>
          </v-col>

          <v-col
            cols="6"
          >
            <span
            >
              {{ $vuetify.lang.t('$vuetify.healthReport.healthReportDates') }} :
            </span>

            <span
              class="ml-2 black--text"
            >
              {{ healthReport.startDate | moment('YYYY-MM-DD') }} {{ $vuetify.lang.t('$vuetify.common.to') }} {{ healthReport.endDate | moment('YYYY-MM-DD') }}
            </span>

          </v-col>

        </v-row>
      </v-card-text>

      <v-divider class="my-4"></v-divider>

      <v-subheader>
        {{ $vuetify.lang.t('$vuetify.healthReport.analysisResult') }}
      </v-subheader>

      <v-card-text
        v-if="healthReport"
      >
        <v-row>
          <v-col
            cols="3"
          >
            <blood-pressure
              :datas="healthReport.bloodPressureData"
              :analysis="healthReport.bloodPressureAnalysis"
            />
          </v-col>

          <v-col
            cols="3"
          >
           <blood-oxygen
              :datas="healthReport.bloodOxygenData"
              :analysis="healthReport.bloodOxygenAnalysis"
            />
          </v-col>

          <v-col
            cols="3"
          >
           <heart-rate
              :datas="healthReport.heartRateData"
              :analysis="healthReport.heartRateAnalysis"
            />
          </v-col>

          <v-col
            cols="3"
          >
           <sport
              :datas="healthReport.sportData"
              :analysis="healthReport.sportAnalysis"
            />
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info darken-1"
          @click="handleGoBack"
        >
          {{ $vuetify.lang.t('$vuetify.common.goBack') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      BloodPressure: () => import('./reports/BloodPressure'),
      BloodOxygen: () => import('./reports/BloodOxygen'),
      HeartRate: () => import('./reports/HeartRate'),
      Sport: () => import('./reports/Sport')
    },

    props: {
      id: {
        type: String,
        default: () => null
      }
    },

    data () {
      return {
        healthReport: null
      }
    },

    computed: {

      ...mapGetters({
        currentHealthReport: 'healthReport/currentHealthReport'
      })
    },

    created () {
      this.healthReport = null
      this.healthReportShow(this.id).then( () => {
        this.healthReport = this.currentHealthReport
      })
    },

    methods: {

      ...mapActions({
        healthReportShow: 'healthReport/show'
      }),

      handleGoBack () {
        this.$router.go(-1)
      }
    }
  }
</script>
